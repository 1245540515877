import {Outlet} from "react-router-dom";
import {AppShell} from "@mantine/core";
import {useDisclosure} from "@mantine/hooks";
import Header from "./Header";
import WrapLayoutContext from "../../contexts/_layout/WrapLayoutContext";
import Navbar from "./Navbar";

/**
 * Renders <Outlet/> inside a layout.
 * @constructor
 */
function Layout() {
    const [mobileOpened, { close: closeMobile, toggle: toggleMobile }] = useDisclosure();
    const [desktopOpened, { toggle: toggleDesktop }] = useDisclosure(true);

    return (
        <WrapLayoutContext>
            <AppShell
                layout="alt"
                header={{ height: 60 }}
                navbar={{width: 300, breakpoint: 'sm', collapsed: { mobile: !mobileOpened, desktop: !desktopOpened }}}
                px="md"
                bg="dark.8"
            >
                <Header
                    mobileOpened={mobileOpened}
                    desktopOpened={desktopOpened}
                    toggleMobile={toggleMobile}
                    toggleDesktop={toggleDesktop}
                />

                <Navbar
                    closeMobile={closeMobile}
                />

                <AppShell.Main>
                    <Outlet/>
                </AppShell.Main>
            </AppShell>
        </WrapLayoutContext>
    );
}

export default Layout;

// TODO: Layout.tsx
//  - Implement navbar on left. [currently it's empty]