import ListQuery from "./ListQuery";
import Page from "../Page";
import Kill from "./Kill";
import Api from "../Api";
import HttpClient from "../HttpClient";

class KillApi {
    /**
     * GET /api/kill/
     *
     * Get paginated list of kills.
     * Returns Page<Kill>
     * @param q
     */
    // noinspection JSUnusedGlobalSymbols
    static getKills(q: ListQuery): Promise<Page<Kill>> {
        const url = Api.getUrl('/api/kill/');
        return HttpClient.get<Page<Kill>>(url, { ...q });
    }
}

// noinspection JSUnusedGlobalSymbols
export default KillApi;