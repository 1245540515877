import PageWrapper from "../PageWrapper";
import HoverCard from "../../components/hover-card/HoverCard";
import {Button, SimpleGrid, Text, Title} from "@mantine/core";
import {useEffect, useState} from "react";
import ComputerConfig from "../../api/computer-config/ComputerConfig";
import ComputerConfigApi from "../../api/computer-config/ComputerConfigApi";
import Notifications from "../../utils/notifications/Notifications";
import ComputerConfigEditDialog from "../../components/computer-config/edit-dialog/ComputerConfigEditDialog";

function SystemSettingsPage() {
    /**
     * If true, <ComputerConfigEditDialog> is shown.
     * @author Kacper Faber
     */
    const [editDefault, setEditDefault] = useState(false);

    /**
     * Default computer configuration.
     * Fetch from API at mount.
     * @author Kacper Faber
     */
    const [config, setConfig] = useState<ComputerConfig>();

    const reload = () => {
        ComputerConfigApi.getDefault()
            .then(setConfig)
            .catch(Notifications.showFetchError);
    }

    const saveDefaultConfig = (a: {maxIdleSeconds: number, programIds: number[]}) => {
        return ComputerConfigApi.editDefault({...a})
    }

    useEffect(reload, []);

    return (
        <>
            <ComputerConfigEditDialog
                save={saveDefaultConfig}
                title="Domyślna konfiguracja"
                config={config}
                opened={editDefault}
                justClose={() => setEditDefault(false)}
                closeAndReload={() => setEditDefault(false)}
            />

            <PageWrapper mode="simple">
                <Title mb="xl">
                    Ustawienia systemu
                </Title>

                <SimpleGrid cols={{ base: 1, xs: 1, sm: 1, md: 2, lg: 2, xl: 3 }}>
                    <HoverCard onClick={() => {}}>
                        <Title mb="md">Domyślna konfiguracja</Title>
                        <Text c="dimmed" mb="md">
                            Edytuj domyślny czas bezczynności oraz śledzone programy.
                            Konfiguracja zostanie zastosowana dla komputerów
                            które nie mają nadpisanych ustawień
                        </Text>
                        <Button onClick={() => setEditDefault(true)} variant="default" mt="md">Edytuj</Button>
                    </HoverCard>
                </SimpleGrid>


            </PageWrapper>
        </>
    );
}

export default SystemSettingsPage;