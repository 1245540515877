import {createContext} from "react";
import LayoutPage from "./LayoutPage";

export type LayoutContextType = {
    selected: LayoutPage | undefined;
    setSelected: (s: LayoutPage | undefined) => void;
}

const notImpl = () => {
    throw new Error("LayoutContext.setSelected is not implemented at this point. Please ensure you\'re in LayoutContext.")
}

const init: LayoutContextType = {
    selected: undefined,
    setSelected: notImpl
}

/**
 * Holds information about layout.
 * For example: Current page etc.
 * @author Kacper Faber
 */
const LayoutContext = createContext<LayoutContextType>(init);

export default LayoutContext;