// noinspection DuplicatedCode

import SessionStorage from "../storage/Storage";

class HttpClient {
    /**
     * Converts response.json() to <T>, only if status code is OK.
     * Otherwise, will throw an error.
     * @param response Response
     */
    static async getData<T>(response: Response): Promise<T> {
        if (response.status < 200 || response.status > 299) {
            throw new Error('Received status code is not OK.')
        }
        return await response.json() satisfies T;
    }

    static async getNoContent(response: Response): Promise<void> {
        if (response.status < 200 || response.status > 299) {
            throw new Error('Received status code is not OK.')
        }
    }

    /**
     * Construct list of headers.
     * Will skip authorization header, if token in session storage is not found.
     * @author Kacper Faber
     */
    static getHeaders(): any {
        const token = SessionStorage.getAccessToken();

        if (token === null) {
            return new Headers({
                'Content-Type': 'application/json'
            });
        }

        return new Headers({
            'Authorization': `Access-Token ${token}`,
            'Content-Type': 'application/json'
        });
    }

    /**
     * Get query object, skip undefined fields.
     * @author Kacper Faber
     * @param params
     */
    static getParams(params: any) {
        const obj = { ...params };
        Object.keys(obj).forEach(key => {
            if (obj[key] === undefined) {
                delete obj[key];
            }
        });
        return obj;
    }

    static getUrl(url: string, params: any): string {
        let filteredParams = this.getParams(params);
        const searchParams = new URLSearchParams(filteredParams);
        return `${url}?${searchParams}`;
    }

    // noinspection JSUnusedGlobalSymbols
    static async get<T>(url: string, params = {}): Promise<T> {
        const urlWithParams = HttpClient.getUrl(url, params);
        const headers = HttpClient.getHeaders();
        const init: any = {method:'GET', headers, mode: 'cors'};
        const response = await fetch(urlWithParams, init);
        if (response.status < 200 || response.status > 299) {
            throw new Error('Invalid');
        }
        return await response.json() satisfies T
    }

    static async put<T>(url: string, params: any = {}, body: any): Promise<T> {
        const urlWithParams = HttpClient.getUrl(url, params);
        const headers = HttpClient.getHeaders();
        const bodyJson = JSON.stringify(body);
        const init: any = { method: 'PUT', headers, body: bodyJson, mode: 'cors' };
        const response = await fetch(urlWithParams, init);
        return this.getData(response);
    }

    static async post<T>(url: string, params: any = {}, body: any): Promise<T> {
        const urlWithParams = HttpClient.getUrl(url, params);
        const headers = HttpClient.getHeaders();
        const bodyJson = JSON.stringify(body);
        const init: any = { method: 'POST', headers, body: bodyJson, mode: 'cors' };
        const response = await fetch(urlWithParams, init);
        return this.getData(response);
    }

    static async postExpectNoContent<T>(url: string, params: any = {}, body: any): Promise<void> {
        const urlWithParams = HttpClient.getUrl(url, params);
        const headers = HttpClient.getHeaders();
        const bodyJson = JSON.stringify(body);
        const init: any = { method: 'POST', headers, body: bodyJson, mode: 'cors' };
        const response = await fetch(urlWithParams, init);
        return this.getNoContent(response);
    }

    static async deleteExpectNoContent(url: string, params: any = {}, body: any): Promise<void> {
        const urlWithParams = HttpClient.getUrl(url, params);
        const headers = HttpClient.getHeaders();
        const bodyJson = JSON.stringify(body);
        const init: any = { method: 'DELETE', headers, body: bodyJson, mode: 'cors' };
        const response = await fetch(urlWithParams, init);
        return this.getNoContent(response);
    }
}

export default HttpClient;

// TODO: HttpClient.ts
//  - Optimize code, these functions are duplicates but with different methods.