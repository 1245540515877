import LayoutPage from "../contexts/_layout/LayoutPage";
import {ReactNode} from "react";
import ComputerPage from "../pages/computer/Computer";
import ComputerDetailsPage from "../pages/computer/details/ComputerDetails";
import KillPage from "../pages/kill/Kill";
import NotFound from "../pages/not-found/NotFound";
import IndexPage from "../pages/index/Index";
import SystemSettings from "../pages/system-settings/SystemSettings";

export type Route = {
    path: string;
    page: LayoutPage;
    element: ReactNode;
};

/**
 * Defines possible routes.
 * Please be aware, that routes are for authenticated users.
 * So, they should be under <SecuredPage/> in BrowserRouter.
 * @author Kacper Faber
 */
const Routes: Route[] = [
    {
        path: 'computer',
        element: <ComputerPage/>,
        page: 'computer_list'
    },

    {
        path: 'kill',
        element: <KillPage/>,
        page: 'kill_list'
    },

    {
        path: '*',
        element: <NotFound/>,
        page: 'not_found'
    },

    {
        path: '',
        element: <IndexPage/>,
        page: 'index'
    },

    {
        path: 'computer/:computerId',
        element: <ComputerDetailsPage/>,
        page: 'computer_details'
    },

    {
        path: 'system-settings',
        element: <SystemSettings/>,
        page: 'system_settings'
    }
];

export default Routes;