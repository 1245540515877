import PageWrapper from "../PageWrapper";
import {SimpleGrid, Text, Title} from "@mantine/core";
import HoverCard from "../../components/hover-card/HoverCard";
import {useEffect, useState} from "react";
import Notifications from "../../utils/notifications/Notifications";
import Stats from "../../api/stats/Stats";
import StatsApi from "../../api/stats/StatsApi";

function IndexPage() {
    const [stats, setStats] = useState<Stats>();

    const reload = () => {
        StatsApi.getStats()
            .then(setStats)
            .catch(Notifications.showFetchError);
    };

    useEffect(reload, []);

    return (
        <PageWrapper page="index" mode="simple_animated">
            <Title mb="xl">
                Automatyczne wyłączanie programów
            </Title>

            <SimpleGrid cols={{ base: 2, xl: 3, md: 2, sm: 1, xs: 1 }}>
                <HoverCard onClick={() => {}}>
                    <Title>
                        {stats?.totalComputers}
                    </Title>
                    <Text>Obsługiwanych komputerów</Text>
                </HoverCard>

                <HoverCard onClick={() => {}}>
                    <Title>
                        {stats?.totalKills}
                    </Title>
                    <Text>Wyłączeń</Text>
                </HoverCard>
            </SimpleGrid>
        </PageWrapper>
    );
}

export default IndexPage;