import {ReactNode, useEffect, useState} from "react";
import User from "../../api/user/User";
import AuthContext from "./AuthContext";
import AuthenticationApi, {AuthenticationResult} from "../../api/auth/AuthenticationApi";
import Storage from "../../storage/Storage";

type WrapAuthContextProps = {
    children: ReactNode;
}

/**
 * Wraps your children inside an AuthContext.Provider block.
 * It initializes an entire context, and generally gives you some method to work
 * with authentication.
 * @param props
 * @constructor
 */
function WrapAuthContext(props: WrapAuthContextProps) {
    const [user, setUser] = useState<User>();

    const setUndefined = () => setUser(undefined);

    const reload = () => {
        if (Storage.getAccessToken() === null) {
            setUndefined();
            return;
        }

        AuthenticationApi.me()
            .then(setUser)
            .catch(setUndefined);
    }

    const logout = () => {
        setUndefined();
        Storage.setAccessToken(null);
    }

    /**
     * Function to call after successfully logged in.
     * Must be called before calling any API endpoint that require authentication.
     * It sets access token here.
     * @param result
     */
    const onLoginSuccess = (result: AuthenticationResult) => {
        Storage.setAccessToken(result.accessToken);
    }

    /**
     * Login user with username and password.
     * After received an access token, calling Au
     * @param username
     * @param password
     */
    const login = (username: string, password: string) =>
        AuthenticationApi.authenticate({username, password})
            .then(onLoginSuccess)
            .then(AuthenticationApi.me)
            .then((user) => { setUser(user); return user; })

    useEffect(reload, []);

    return (
        <AuthContext.Provider
            value={{user, reload, login, logout}}
            children={props.children}
        />
    );
}

export default WrapAuthContext;

// TODO: WrapAuthContext.ts
//  - Make code better. It should work, but can be prettier.