import ListQuery from "./ListQuery";
import Api from "../Api";
import HttpClient from "../HttpClient";
import Page from "../Page";
import Computer from "./Computer";
import EditQuery from "./EditQuery";

// noinspection JSUnusedGlobalSymbols
class ComputerApi {
    /**
     * Get paginated computers from API.
     * We can add some queries to improve the experience.
     * @author Kacper Faber
     */
    // noinspection JSUnusedGlobalSymbols
    static getComputers(q: ListQuery) {
        const url = Api.getUrl('/api/computer/');
        return HttpClient.get<Page<Computer>>(url, { ...q });
    }

    static getComputer(id: number) {
        const url = Api.getUrl(`/api/computer/${id}/`);
        return HttpClient.get<Computer>(url, {});
    }

    /**
     * **PUT /api/computer/{id}/**
     *
     * Edit computer's data like title, description etc.
     * @param e
     */
    static editComputer(e: EditQuery) {
        const url = Api.getUrl(`/api/computer/${e.id}/`);
        const body: Omit<EditQuery, "id"> = e;
        return HttpClient.put(url, {}, body);
    }

    /**
     * **DELETE /api/computer/{id}/**
     *
     * Deletes computer.
     * @param id
     */
    static deleteComputer(id: number) {
        const url = Api.getUrl(`/api/computer/${id}/`);
        return HttpClient.deleteExpectNoContent(url, {}, {});
    }

    // /**
    //  * **PUT /api/computer/{id}/**
    //  *
    //  * Update programs related with computer.
    //  * @param query
    //  */
    // static updatePrograms(query: EditProgramsQuery) {
    //     const url = Api.getUrl(`/api/computer/${query.id}/`);
    //     const body: Omit<EditProgramsQuery, "id"> = query;
    //     return HttpClient.put(url, {}, body);
    // }
}

// noinspection JSUnusedGlobalSymbols
export default ComputerApi;