import {Card} from "@mantine/core";
import {ReactNode} from "react";

type HoverCardProps = {
    /**
     * If not set, card have no hover effect.
     * But currently, hover effect does not work anyway.
     * @author Kacper Faber
     */
    onClick?: () => void;

    children: ReactNode | ReactNode[];
}

/**
 * Warning:
 * Click and hover effect don't work anyway.
 * @param props
 * @constructor
 */
function HoverCard(props: HoverCardProps) {
    return (
        <Card bg="dark.7" p="25px">
            { props.children }
        </Card>
    );
}

export default HoverCard;

// TODO: HoverCard
//  - Implement hover effect and clickable