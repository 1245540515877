import {Anchor, Group, Text} from "@mantine/core";
import {useContext} from "react";
import AuthContext from "../../contexts/auth/AuthContext";
import Notifications from "../../utils/notifications/Notifications";
import {useNavigate} from "react-router-dom";

type CurrentUserProps = {}

/**
 * Current user section in Header.
 * @param _
 * @constructor
 */
function CurrentUser(_: CurrentUserProps) {
    const authContext = useContext(AuthContext);

    /**
     * Navigate function.
     * From react-router-dom
     * @author Kacper Faber
     */
    const navigate = useNavigate();

    const getUsername = () => {
        return authContext.user?.username;
    }

    const logout = () => {
        authContext.logout();
        Notifications.showLogoutSuccess();
        navigate("/login");
    }

    return (
        <Group gap="sm" h="100%" align="center">
            <Text size="sm">
                {getUsername()}
            </Text>

            <Anchor size="sm" onClick={logout}>
                (Wyloguj się)
            </Anchor>
        </Group>
    );
}

export default CurrentUser;

// TODO: CurrentUser
//  - Improvements.
//  - Maybe modify AuthContext.logout to show notification instantly.