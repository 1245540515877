import ComputerTableView from "./table/ComputerTableView";

type ComputerViewProps = {}

/**
 * Component to display computers in /computer.
 * Maybe in future it will provide more than one presentation style.
 *
 * Currently, we're using <ComputerTableView/> implementation.
 * @param _props
 * @constructor
 */
function ComputerView(_props: ComputerViewProps) {
    return (
        <ComputerTableView/>
    );
}

export default ComputerView;

// TODO: ComputerView.tsx
//  - Implement ComputerView.tsx - add second view implementation and switching between them.