// noinspection JSUnusedGlobalSymbols

import EditComputerConfigQuery from "./EditComputerConfigQuery";
import Api from "../Api";
import HttpClient from "../HttpClient";
import EditDefaultComputerConfigQuery from "./EditDefaultComputerConfigQuery";
import ComputerConfig from "./ComputerConfig";

class ComputerConfigApi {
    /**
     * Edit computer configuration.
     * @param q
     */
    static edit(q: EditComputerConfigQuery) {
        const url = Api.getUrl('/api/computer-config/');
        const computerId = q.computerId;
        const body: Omit<EditComputerConfigQuery, 'computerId'> = q;
        return HttpClient.postExpectNoContent(url, { computerId }, body);
    }

    /**
     * Edit default computer configuration.
     * @param q
     */
    static editDefault(q: EditDefaultComputerConfigQuery) {
        const url = Api.getUrl('/api/computer-config/default/');
        return HttpClient.postExpectNoContent(url, {}, { ...q });
    }

    static getDefault() {
        const url = Api.getUrl('/api/computer-config/default/');
        return HttpClient.get<ComputerConfig>(url, {});
    }
}

// noinspection JSUnusedGlobalSymbols
export default ComputerConfigApi;