import {useEffect, useState} from "react";
import ListQuery from "../../api/computer/ListQuery";
import ComputerApi from "../../api/computer/ComputerApi";
import Page from "../../api/Page";
import Computer from "../../api/computer/Computer";
import Notifications from "../../utils/notifications/Notifications";
import {ActionIcon, Group, Menu, MenuLabel, Title} from "@mantine/core";
import PageWrapper from "../PageWrapper";
import ComputerView from "./view/ComputerView";
import {IconDotsVertical} from "@tabler/icons-react";
import ComputerEditDialog from "./ComputerEditDialog";
import ComputerPageContext from "../../contexts/pages/computer/ComputerPageContext";

/**
 * Initial computer's ListQuery.
 * @author Kacper Faber
 */
const initQuery: ListQuery = {};

/**
 * ComputerPage.
 * [Not implemented yet]
 * @constructor
 */
function ComputerPage() {
    const [fetching, setFetching] = useState(false);

    /**
     * ListQuery
     * @author Kacper Faber
     */
    const [query, setQuery] = useState<ListQuery>(initQuery);

    /**
     * Computers data.
     * May be undefined.
     * @author Kacper Faber
     */
    const [page, setPage] = useState<Page<Computer>>();

    /**
     * Computer to edit.
     * If set, ComputerEditDialog is visible.
     * @author Kacper Faber
     */
    const [computerToEdit, setComputerToEdit] = useState<Computer>();

    /**
     * Reload computers data [page state].
     * Called by hook, when query state changed.
     * @author Kacper Faber
     */
    const reload = () => {
        setFetching(true);

        ComputerApi.getComputers(query)
            .then(setPage)
            .catch(Notifications.showFetchError)
            .finally(() => setFetching(false));
    }

    useEffect(reload, [query]);

    return (
        <PageWrapper page="computer_list">
            <ComputerPageContext.Provider value={{
                page: [page, setPage],
                computerToEdit: [computerToEdit, setComputerToEdit],
                query: [query, setQuery],
                isFetching: fetching
            }}>
                <Group justify="space-between" mb="xl">
                    <Title>
                        Komputery
                    </Title>

                    <Menu width={200}>
                        <Menu.Target>
                            <ActionIcon variant="subtle">
                                <IconDotsVertical/>
                            </ActionIcon>
                        </Menu.Target>

                        <Menu.Dropdown>
                            <MenuLabel>Zarządzanie</MenuLabel>
                        </Menu.Dropdown>
                    </Menu>
                </Group>

                <ComputerView/>

                <ComputerEditDialog
                    computer={computerToEdit}
                    opened={computerToEdit !== undefined}
                    justClose={() => {
                        setComputerToEdit(undefined)
                    }}
                    closeAndReload={() => {
                        setComputerToEdit(undefined);
                        reload();
                    }}
                />
            </ComputerPageContext.Provider>
        </PageWrapper>
    );
}

export default ComputerPage;