import PageWrapper from "../PageWrapper";
import {Button, Container, Text, Title} from "@mantine/core";
import {useNavigate} from "react-router-dom";

/**
 * Basic Not Found - 404 page.
 * @author Kacper Faber
 */
function NotFound() {
    const navigate = useNavigate();

    const goHome = () => {
        navigate('/');
    }

    return (
        <PageWrapper mode="simple">
            <Container size="sm">
                <Title fw={500} mt="xl" mb="sm">
                    Strona nie została odnaleziona
                </Title>

                <Text c="dimmed" fs="sm" mb="sm">
                    Jeśli uważasz, że to błąd skontaktuj się z administratorem
                </Text>

                <Button onClick={goHome} size="sm" variant="default">
                    Zabierz mnie stąd
                </Button>
            </Container>
        </PageWrapper>
    );
}

export default NotFound;

// TODO: NotFound.tsx
//  - Make NotFound page nice.